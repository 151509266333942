import React, { useState, useEffect } from 'react';

function App() {

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const menuOpenClose = () => {
    setMenuOpen(!menuOpen);
  };

  return (
      <>
        <header className={isSticky ? 'scrolled' : ''}>
          <nav className={`navbar container  navbar-expand-md navbar-dark bg-transparent`}>
            <div className="container-fluid">
              <a className="navbar-brand d-md-none" href="#">
                <img src="./img/logo.png" alt="" />
              </a>
              <button onClick={menuOpenClose}
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#tokenomics">
                      Tokenomics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#roadmap">Roadmap</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#social">Social</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section id="home" className="king text-center" style={{ backgroundImage: 'url("./img/kitten.jpg")' }}>
            <div className="overlay">
              <div className="container">
                <h1 className="text-white">
                  The New Meme of <b>Kings</b>
                </h1>
                <p className="text-white mb-5">
                  Welcome to the Reign of Intelligence!
                </p>
                <label className="d-block">Contract Address</label>
                <input
                    type="text"
                    className="ca-box"
                    defaultValue="BjGBY8mFc6pVAaSajYXvAwVWUcue7cLFp9jwSZdgYARz"
                    readOnly=""
                />
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div className="container">
              <div className="row inner-box">
                <div className="col-md-6 col-12">
                  <h3 className="text-white mt-4">Join Our Kingdom</h3>
                  <p>
                    Congratulations on joining us, and get ready to meet the new ruler
                    of the crypto world, a combination of intelligence and power:
                    KittenZilla! This unique MEME Coin is designed to push the boundaries
                    of the markets and leave all other coins behind. With its unique
                    smart contract algorithms and exceptional security features,
                    KittenZilla offers opportunities worthy of a royal treasure hidden deep
                    within the blockchain.
                    <br />
                    <br />
                    KittenZilla is more than just a MEME Coin. With its unique personality
                    and intelligence, it has the potential to create a phenomenon in
                    the crypto universe. With its incredible intelligence, it will
                    turn market fluctuations in its favor and provide unique
                    advantages to its investors. Other coins may imitate it, but they
                    will never reach the same level. Why? Because KittenZilla bears the
                    royal seal, and this seal promises an extraordinary investment
                    experience.
                    <br />
                    <br />
                    Take your place on the first day of your reign and watch KittenZilla
                    upset all predictions. Come on, be part of this crypto revolution
                    and invest in the currency of the future today!
                  </p>
                </div>
                <div className="col-md-6 col-12">
                  <img
                      className="img-fluid rounded-4"
                      src="./img/kittenzilla.jpg"
                      alt="About KittenZilla"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="tokenomics" className="tokenomics">
            <div className="container">
              <div className="row inner-box">
                <div className="col-md-6 col-12 pb-5">
                  <h3 className="text-white mt-4">TOKENOMICS</h3>
                  <div className="tokenomics-info">
                    <ul>
                      <li id="tokenomics-li">
                        <div>
                          NAME: <span className="fw-bold text-black">KittenZilla</span>
                        </div>
                        <hr />
                        <div>
                          SYMBOL: <span className="fw-bold text-black">KTZ</span>
                        </div>
                        <hr />
                        <div>
                          SUPPLY: <span className="fw-bold text-black">10.000.000</span>
                        </div>
                        <hr />
                        <div>
                          TEAM TOKENS: <span className="fw-bold text-black">NO</span>
                        </div>
                        <hr />
                        <div>
                          LP TOKEN: <span className="fw-bold text-black">BURN</span>
                        </div>
                        <hr />
                        <div>
                          TAX: <span className="fw-bold text-black">0%</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-12 token_bg">
                  <img src="./img/king.jpg" alt="" />
                </div>
              </div>
            </div>
          </section>
          <section id="roadmap" className="roadmap">
            <div className="container">
              <h3 className="text-white text-center">ROADMAP</h3>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 1</h4>
                    <ul>
                      <li>Presale on Pinksale</li>
                      <li>Launch of trading on Raydium</li>
                      <li>AveDex, DexTools, DexScreener Trending</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 2</h4>
                    <ul>
                      <li>CoinGecko and CoinMarketCap Listings</li>
                      <li>Main marketing campaign through Twitter, Telegram</li>
                      <li>10.000 Holders</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 3</h4>
                    <ul>
                      <li>Increasing Holders Amount</li>
                      <li>Partnerships with Top callers and influencers</li>
                      <li>Applications for CEX Listings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="social">
          <div className="container text-center text-white">
            <h3 className="">Follow Us!</h3>
            <p className="">
              Stay Connected for the latest updates, news, and exciting developments
              in the KittenZilla.
            </p>
            <div className="socialBox">
              <a href="https://twitter.com/KittenZillaSol">
                <i className="fa-brands fa-x-twitter" />
              </a>
              <a href="https://t.me/KittenZillaSol">
                <i className="fa-brands fa-telegram" />
              </a>
            </div>
          </div>
        </footer>
      </>
  );
}

export default App;
